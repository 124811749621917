// Get image path by size
export function getImagePathBySize(pathObj, size, location = 0) {
  try {
    let resPath = '';
    pathObj[location]?.sizes.forEach(e => {
      if(Object.prototype.hasOwnProperty.call(e, size)){
        resPath = e.path;
      }
    });
    return resPath || pathObj[location]?.path;
  } catch (error) {
    return '';
  }
}