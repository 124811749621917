import { map } from 'lodash';
// import { useState, useEffect } from 'react';
import { AccessControl } from 'accesscontrol';
// Hook
import useAuth from './useAuth';

// ----------------------------------------------------------------------

export default function useAccessControl(a,b) {
  const { user } = useAuth();
  let resVar = false;
  try {

    const rolesArr = map(user?.roles, 'key')
    if (rolesArr.includes('SUPER_ADMIN')) {
      // if(b === 'CHAT') {
      //   return false;
      // }
      return true;
    }

    const permissions = window.localStorage.getItem('permissions');
    const permissionsArr = JSON.parse(permissions)
    const ac = new AccessControl(permissionsArr);

    switch (a) {
      case 'createOwn':
        resVar = ac.can(rolesArr).createOwn(b).granted
        break;
      case 'createAny':
        resVar = ac.can(rolesArr).createAny(b).granted
        break;
      case 'readOwn':
        resVar = ac.can(rolesArr).readOwn(b).granted
        break;
      case 'readAny':
        resVar = ac.can(rolesArr).readAny(b).granted
        break;
      case 'editOwn':
        resVar = ac.can(rolesArr).editOwn(b).granted
        break;
      case 'editAny':
        resVar = ac.can(rolesArr).updateAny(b).granted
        break;
      case 'deleteOwn':
        resVar = ac.can(rolesArr).deleteOwn(b).granted
        break;
      case 'deleteAny':
        resVar = ac.can(rolesArr).deleteAny(b).granted
        break;
      default:
        resVar = false;
        break;
    }
  } catch (err) {
    // console.error(err);
  }
  return resVar;
}
