// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

// Get Media
export const getMediaImages = async (dataBody) => {
  const response = await axios.get(`/catalog-images${dataBody}`);
  return response.data || response;
}

// Update Media
export const updateMedia = async (id, dataBody) => {
  const response = await axios.put(`/catalog-images/${id}`, dataBody);
  return response.data || response;
}

// Delete Media
export const deleteMedia = async (id) => {
  const response = await axios.delete(`/catalog-images/${id}`);
  return response.data || response;
}