import PropTypes from 'prop-types';
import { findIndex, filter, uniqBy } from 'lodash';
import { useEffect, useState} from 'react';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Paper, IconButton } from '@mui/material';
// utils
import {getImagePathBySize} from '../../utils/getImagePath';
// Components
import Iconify from '../Iconify';
import LightboxModal from '../LightboxModal';
// Section
import MediaDialog from '../../sections/media/MediaDialog';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  '&:hover': { opacity: 0.72 }
}));

// ----------------------------------------------------------------------



MoreAttachments.propTypes = {
  onUnSelectAll: PropTypes.any,
  selcNewAttached: PropTypes.any,
  attachments: PropTypes.array,
};

export default function MoreAttachments({ onUnSelectAll, selcNewAttached, attachments }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMediaDialogOpen, setIsMediaDialogOpen] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  useEffect(()=>{
    if (attachments?.length) {
      setSelectedAttachments(prevState => ([...attachments, ...prevState]))
    }
  },[attachments])

  const imagesLightbox = selectedAttachments.map((img) => img.path);

  const handleOpenLightbox = (url) => {
    const selectedImage = findIndex(imagesLightbox, (index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  }
  const handleCloseLightbox = () => {
    setOpenLightbox(false);
  }

  const hadlerSelectMedia = (data) => {
    if(data?.length > 0){
      const imageData = (data[0]?.id) ? {id:data[0]?.id, path: getImagePathBySize([data[0]], 's80') || '/images/img_placeholder.svg'} : {};
      setSelectedAttachments(prevState => ([imageData, ...prevState]));
    }
    setIsMediaDialogOpen(false)
  }

  const handleRemove = (fileId) => {
    setSelectedAttachments(filter(selectedAttachments, (item) => item.id !== fileId));
  };

  useEffect(()=>{
    if(onUnSelectAll) {
      setSelectedAttachments([])
    }
  },[onUnSelectAll])

  useEffect(()=>{
    selcNewAttached(selectedAttachments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedAttachments])

  return (
    <>
      {uniqBy(selectedAttachments, 'id').map((file, index) => {
        const { id, path } = file;

        return (
          <Box
            key={index}
            sx={{
              p: 0,
              m: 0.5,
              width: 64,
              height: 64,
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Paper
              variant="outlined"
              component="img"
              src={path}
              onClick={() => handleOpenLightbox(path)}
              sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', cursor: 'zoom-in' }}
            />
            <Box sx={{ top: 4, right: 4, position: 'absolute' }}>
              <IconButton
                size="small"
                onClick={() => handleRemove(id)}
                sx={{
                  p: '2px',
                  color: 'error.main',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  '&:hover': {
                    bgcolor: 'error.main',
                    color: '#fff',
                  }
                }}
              >
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            </Box>
          </Box>
        );
      })}

      <DropZoneStyle onClick={()=> setIsMediaDialogOpen(true) }>
        {/* <Box component={Icon} icon={plusFill} sx={{ color: 'text.secondary' }} /> */}
        <Iconify icon={'material-symbols:add'} />
      </DropZoneStyle>

      <LightboxModal
        images={imagesLightbox}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={handleCloseLightbox}
      />

      {isMediaDialogOpen &&
        <MediaDialog
          onUpdateMedia={hadlerSelectMedia}
          onClose={()=>setIsMediaDialogOpen(false)}
          isMDOpen={isMediaDialogOpen}
          btnText={'Select Image'}
        />
      }
    </>
  );
}
