import { createContext, useEffect, useReducer } from 'react';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import io from "socket.io-client";
// utils
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  errorData: '',
  user: null,
  socket: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SOCKETCONNACTION: (state, action) => {
    const {socket} = action.payload;
    return {
      ...state,
      socket,
    };
  },
  LOGIN: (state, action) => {
    const payloadData = action.payload;
    let payloadState = {}
    if(payloadData.user) {
      payloadState = {
        ...state,
        isAuthenticated: true,
        user: payloadData.user,
      };
    } else {
      payloadState = {
        ...state,
        isAuthenticated: false,
        errorData: payloadData.isError,
        user: null,
      };
    }
    return payloadState
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
          await setSession(accessToken);
          const response  = await axios.get('/profile');
          const resData = response.data; let userData = null; let isAuthenticated = false;
          if(resData) {
            userData = resData.data;
            userData.displayName = (userData.firstName && userData.firstName !== null) ? `${userData.firstName} ${userData.lastName}` : userData.username;
            isAuthenticated = true;
          }
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: userData,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    if (state?.isAuthenticated) {
      (async() => {
        const fullToken = JSON.parse(window.localStorage.getItem('accessToken'));
        const socketConn = await io.connect(`${process.env.REACT_APP_SOCKET_URL}`, {
          extraHeaders: { token: fullToken?.accessToken },
          // forceNew: true,
        })
        dispatch({
          type: 'SOCKETCONNACTION',
          payload: {
            socket: socketConn,
          },
        });
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.isAuthenticated]);

  const login = async (formData) => {
    formData.platform = 'browser'
    const response = await axios.post('/login', formData);
    const resData = response.data; let userData = null;
    if(resData) {
      userData = resData.data;
      try {
        if (userData?.roles.length === 1 && findIndex(userData?.roles, {id: 4}) !== -1) {
          return {success: false, message: 'Incorrect email or password!'}
        }
        const fullToken = JSON.stringify({accessToken: resData.token, refresh: resData.refreshToken});
        await setSession(fullToken);
        userData.displayName = (userData.firstName && userData.firstName !== null) ? `${userData.firstName} ${userData.lastName}` : userData.username;
      } catch (err) { console.error(err); }
    }
    dispatch({
      type: 'LOGIN',
      payload: {
        isError: response,
        user: userData,
      },
    });
    return resData;
  };

  const register = async (email, password, firstName, lastName, platform = 'browser') => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
      platform
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateProfile = async (formData) => {
    formData.platform = 'browser'
    const response = await axios.put('/profile', formData);
    const resData = response.data; let userData = null;
    if(resData && resData.success){
      userData = resData.data;
      userData.displayName = (userData.firstName && userData.firstName !== null) ? `${userData.firstName} ${userData.lastName}` : userData.username;
      dispatch({
        type: 'LOGIN',
        payload: {
          user: userData,
        },
      });
    }
    return resData || response;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };