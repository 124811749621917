import { createSlice } from '@reduxjs/toolkit';
import { find } from 'lodash'
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  notifications: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Notifications
    getNotificationsSuccess(state, action) {
      const payloadData = action.payload;
      if (payloadData?.length) {
        const notiArr = []
        payloadData?.forEach(item => {
          item.type = item?.data?.type
          item.title = item?.data?.title
          item.message = item?.data?.message
          delete item.data
          notiArr?.push(item)
        });

        state.notifications = notiArr;
      }
      state.isLoading = false;
    },

    onNotifStatusChange(state, action) {
      const resData = action.payload;
      state.notifications.map(item => {
        if (item?.senderProfile?.id === resData?.id) {
          item.senderProfile.isOnline = resData?.isOnline
        }
        return item;
      });
    },

    // ON Receive Notification
    onReceiveNot(state, action) {
      const newMessage = action.payload;
      newMessage.type = (newMessage.type) ? newMessage?.type : 'SMS';
      if (find(state.notifications, {conversationId: newMessage?.conversationId}) && newMessage?.type !== 'contact_form_entry') {
        const newData = state.notifications.map((item)=>{
          if(item?.conversationId) {
            return newMessage
          }
          return item
        })
        state.notifications = newData
      } else {
        state.notifications.unshift(newMessage)
      }

      state.isLoading = false;
    },

    markNotiAsRead(state, action) {
      const {id, type} = action.payload;
      if (type === 'contact_form_entry') {
        state.notifications = state.notifications.filter(item => item?.id !== id);
      }
      if (find(state.notifications, {id}) && type === 'SMS') {
        state.notifications = state.notifications.filter(item => item?.conversationId !== id);
      }
    },

    resetNotifications(state, action) {
      const type = action.payload;
      if (type === 'ALL') {
        state.notifications = []
      } else {
        state.notifications = state.notifications.filter(item => item?.type !== type);
      }
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onReceiveNot, onNotifStatusChange, markNotiAsRead, resetNotifications } = slice.actions;

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/notifications?limit=0&filter[unread]=true&sortBy=createdAt&sortOrder=desc');
      const apiRes = response.data || response;

      if (apiRes && apiRes?.success) {
        dispatch(slice.actions.getNotificationsSuccess(apiRes?.data?.rows));
      }else{
        dispatch(slice.actions.hasError((apiRes && apiRes?.errorMsg) ? apiRes.errorMsg : "Something went wrong!"));
      }

      return apiRes;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return {success:false, message:error?.message};
    }
  };
}