// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// import Label from '../../../components/Label';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  organization: getIcon('ic_organization'),
  question: getIcon('ic_question'),
  ecommerce: getIcon('ic_ecommerce'),
  cart: getIcon('ic_cart'),
  speaker: getIcon('ic_speaker'),
  category: getIcon('ic_category'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  inventory: getIcon('ic_inventory'),
  product: getIcon('ic_product'),
  store: getIcon('ic_store'),
  settings: getIcon('ic_settings'),
  media: getIcon('ic_media'),
  appRating: getIcon('ic_appRating'),
  proMatch: getIcon('ic_match'),
  chat: getIcon('ic_chat'),
  catalog: getIcon('ic_catalog'),
  reminder: getIcon('ic_reminder'),
  support: getIcon('ic_support'),
  location: getIcon('ic_location'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     // { title: 'Two', path: '/dashboard/two', icon: ICONS.ecommerce },
  //     // { title: 'Three', path: '/dashboard/three', icon: ICONS.analytics },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.user.list, isAccess: "readAny,USERS"},
          { title: 'New', path: PATH_DASHBOARD.user.new, isAccess: "createAny,USERS"},
        ],
      },
      {
        title: 'stores',
        path: PATH_DASHBOARD.organization.root,
        icon: ICONS.organization,
        children: [
          { title: 'list', path: PATH_DASHBOARD.organization.list, isAccess: "readAny,STORE" },
          { title: 'New', path: PATH_DASHBOARD.organization.new, isAccess: "createAny,STORE"  },
          { title: 'manage store', path: PATH_DASHBOARD.organization.manage, isAccess: "createAny,PRODUCT_MANAGER" },
        ],
      },
      { title: 'inventory', path: PATH_DASHBOARD.inventory, icon: ICONS.inventory, isAccess: "readAny,PRODUCTS" },
      {
        title: 'product',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.product,
        children: [
          { title: 'list', path: PATH_DASHBOARD.product.list, isAccess: "readAny,PRODUCTS"},
          { title: 'New', path: PATH_DASHBOARD.product.new, isAccess: "createAny,PRODUCTS"},
          { title: 'Review', path: PATH_DASHBOARD.product.review, isAccess: "readAny,PRODUCT_REVIEW"},

          { title: 'category', path: PATH_DASHBOARD.product.category, isAccess: "readAny,CATEGORIES" },
          { title: 'brand', path: PATH_DASHBOARD.product.brand, isAccess: "readAny,BRANDS" },
          { title: 'strain', path: PATH_DASHBOARD.product.strain, isAccess: "readAny,STRAINS" },
        ],
      },
      {
        title: 'catalog',
        path: PATH_DASHBOARD.catalog.root,
        icon: ICONS.catalog,
        children: [
          { title: 'list', path: PATH_DASHBOARD.catalog.list, isAccess: "readAny,CATALOG"},
          { title: 'catalog matching', path: PATH_DASHBOARD.catalog.match, icon: ICONS.proMatch, isAccess: "readAny,CATALOG_MATCHING"},
        ]
      },
      { title: 'media', path: PATH_DASHBOARD.media, icon: ICONS.media, isAccess: "readAny,CATALOG_IMAGES" },
      { title: 'orders', path: PATH_DASHBOARD.orders, icon: ICONS.ecommerce },
      {
        title: 'reminder',
        path: PATH_DASHBOARD.reminder.root,
        icon: ICONS.reminder,
        children: [
          { title: 'cart', path: PATH_DASHBOARD?.reminder?.cart, isAccess: "readAny,ABANDONED_CART"},
          { title: 'favorite', path: PATH_DASHBOARD?.reminder?.favorite, isAccess: "readAny,FAVORITE_LIST_EMAIL"},
          { title: 'wishlist', path: PATH_DASHBOARD?.reminder?.wishlist, isAccess: "readAny,WISH_LIST_EMAIL"},
          { title: 'recently viewed', path: PATH_DASHBOARD?.reminder?.recentlyViewed, isAccess: "readAny,RECENT_VIEW_EMAIL"},
          { title: 'notification', path: PATH_DASHBOARD?.reminder?.notification, isAccess: "readAny,FIREBASE_NOTIFICATION_CRUD"},
        ]
      },
      {
        title: 'newsletter',
        path: PATH_DASHBOARD.newsletter.contacts,
        icon: ICONS.speaker,
        children: [
          { title: 'registered users', path: PATH_DASHBOARD.newsletter.registerUsers, isAccess: "readAny,SMS" },
          { title: 'contact list', path: PATH_DASHBOARD.newsletter.contacts, isAccess: "readAny,SMS" },
          { title: 'SMS analytics', path: PATH_DASHBOARD.newsletter.smsAnalytics, isAccess: "readAny,SMS" },
          { title: 'email analytics', path: PATH_DASHBOARD.newsletter.emailAnalytics, isAccess: "readAny,SMS" }
        ]
      },
      {title: 'inquiries', path: PATH_DASHBOARD.inquiryList, icon: ICONS.question, isAccess: "readAny,CONTACT_FORM_ENTRY" },
      {title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat, isAccess: "readAny,CHAT"
        // info: (<Label variant="filled" color="primary">+32</Label>),
      },
      { title: 'location', path: PATH_DASHBOARD.location, icon: ICONS.location, isAccess: "readAny,RESTRICTED_AREA"},
      { title: 'app rating', path: PATH_DASHBOARD.appRating, icon: ICONS.appRating, isAccess: "readAny,APP_RATING"},
      { title: 'support', path: PATH_DASHBOARD.support, icon: ICONS.support, isAccess: "readAny,SUPPORT_TICKET"},
      {
        title: 'settings',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        children: [
          { title: 'account', path: PATH_DASHBOARD.settings.account },
          { title: 'Slider', path: PATH_DASHBOARD.settings.sliderHero, isAccess: "createAny,CMS" },
          { title: 'faqs', path: PATH_DASHBOARD.settings.faqs, isAccess: "readAny,FAQ" },
          { title: 'other pages', path: PATH_DASHBOARD.settings.otherPages, isAccess: "readAny,PAGE" },
          { title: 'role permission', path: PATH_DASHBOARD.settings.permission, isAccess: "createAny,permission_AA" }
        ]
      },
    ],
  },
];

export default sidebarConfig;