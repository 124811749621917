import PropTypes from 'prop-types';
import { useState, useRef, useEffect} from 'react';
import { useSnackbar } from 'notistack';
// material
import { Container, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Dialog
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import MediaList from './MediaList';

// ----------------------------------------------------------------------
// Variables
const formStyle = { width: '1600px', maxWidth: '100%' };
const dialogContentStyle = { marginTop: '15px', paddingTop: '20px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' };


MediaDialog.propTypes = {
  isMDOpen: PropTypes.bool,
  onUpdateMedia: PropTypes.func,
  btnText: PropTypes.string,
  onClose: PropTypes.func,
};

export default function MediaDialog({isMDOpen, onUpdateMedia, btnText = 'Save', onClose}) {
  const { enqueueSnackbar } = useSnackbar();

  const goToElementTopRef = useRef(null);
  const [open, setOpen] = useState(isMDOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMediaIds, setSelectedMediaIds] = useState([]);

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  useEffect(() => {
    if (open) {
      const { current: errorElement } = goToElementTopRef;
      if (errorElement !== null) {
        errorElement.focus();
      }
    }
  }, [open]);

  const selectedMediaHandler = (mediaData) => {
    setSelectedMediaIds(mediaData)
  }

  const handleSubmit = () => {
    if (selectedMediaIds.length > 0) {
      setIsLoading(true)
      onUpdateMedia(selectedMediaIds)
    } else {
      enqueueSnackbar('Please select image', { variant: 'error' });
    }
  }

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        scroll={'paper'}
        maxWidth={'lg'}
      >
        <DialogTitle id="scroll-dialog-title">Select/Upload Media</DialogTitle>
        <DialogContent style={dialogContentStyle} dividers={false}>
          <DialogContentText id="scroll-dialog-description" ref={goToElementTopRef} tabIndex={-1} />
          <Container style={formStyle} >
            <MediaList selectedMedia={selectedMediaHandler} isMediaSelect />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} variant="outlined" onClick={handleClose}>Close</Button>
          <LoadingButton loading={isLoading} onClick={handleSubmit} variant="contained">{btnText}</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}