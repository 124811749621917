import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 4797.29 4527.64">
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"/>
          <g id="_1880833287456">
          <path fill="#43A34C" d="M2203.51 1729.94l396.97 1.64c68.81,-263.71 288.06,-509.34 481.07,-613.47 263.51,-142.16 594.55,-184.5 907.07,-73.61 772.67,274.17 928.94,1418.27 396.98,2015.68l-150.9 130.65c-485.73,340.96 -1228.45,212.61 -1518.94,-301.31 -120.12,-212.52 -171.92,-389.39 -184.27,-641.72 -4.14,-84.57 15.35,-201.79 9.01,-269.4l-285.06 0.92c4.51,357.72 33.35,543.81 -164.31,897.23 -116.14,207.66 -333.98,399.44 -632.86,472.93 -386.88,95.14 -791.01,-27.39 -1033.66,-279.67 -27.27,-28.36 -35.69,-44.6 -63.08,-77.8 -18.64,-22.58 -42.13,-52.51 -59.55,-81.25 -240.98,-397.58 -263.75,-931.2 -69.19,-1329.76 84.77,-173.65 163.04,-268.49 302.2,-382.52 446.29,-365.65 1263.33,-284.37 1582.01,338.07 26.3,51.35 60.83,151.16 86.51,193.39zm-1657.21 1968.45c135.07,165.71 363.75,350.68 549.68,463.92 1602.83,976.2 3719.62,-120.09 3701.2,-1914.57 -6.29,-612.74 -285.53,-1213.59 -712.15,-1590.8 -26.87,-23.77 -43.21,-46.01 -72.94,-67.72l-246.93 -184.74c-370.98,-255.27 -860.99,-395.93 -1336.49,-404.15 -541.44,-9.36 -1105.05,173.32 -1521.05,491.79 -453.55,347.23 -784.48,833.55 -879.67,1427.58 -106.48,664.37 96.63,1261.29 518.35,1778.68z"/>
          <path fill="#221D1F" d="M3514.92 1266.78c535.55,-53.47 811.07,317.82 844.75,812.87 30.64,450.22 -131.16,961.04 -649.12,1017.56 -523.72,57.17 -820.77,-322.4 -844.83,-812.96 -12.42,-253.28 25.23,-500.42 138.14,-690.66 86.11,-145.11 265.92,-302.34 511.06,-326.81zm-2428.97 0.52c528.42,-52.81 819.33,312.5 848.1,806.06 14.25,244.46 -20.5,509.77 -133.64,690.97 -189.53,303.53 -546.73,415.83 -876.63,293.53 -163.55,-60.62 -167.69,-96.14 -248.29,-163.89 -61.05,-51.31 -127.52,-164.03 -162.53,-259.41 -117.67,-320.53 -102.89,-761.47 64.5,-1037.36 90.83,-149.7 255.67,-304.64 508.49,-329.9zm1117.56 462.65c-25.67,-42.23 -60.21,-142.04 -86.51,-193.39 -318.68,-622.45 -1135.72,-703.73 -1582.01,-338.07 -139.17,114.02 -217.43,208.87 -302.2,382.52 -194.56,398.56 -171.79,932.18 69.19,1329.76 17.42,28.73 40.91,58.66 59.55,81.25 27.39,33.2 35.81,49.44 63.08,77.8 242.65,252.28 646.78,374.82 1033.66,279.67 298.88,-73.49 516.72,-265.27 632.86,-472.93 197.66,-353.42 168.82,-539.52 164.31,-897.23l285.06 -0.92c6.34,67.62 -13.15,184.83 -9.01,269.4 12.34,252.33 64.15,429.19 184.27,641.72 290.49,513.92 1033.21,642.27 1518.94,301.31l150.9 -130.65c531.96,-597.41 375.69,-1741.51 -396.98,-2015.68 -312.52,-110.89 -643.57,-68.55 -907.07,73.61 -193.01,104.13 -412.26,349.76 -481.07,613.47l-396.97 -1.64z"/>
          <path fill="#FEFFFE" d="M3527.4 2426.01c-37.09,123.76 -168.08,228.26 -328.08,185.82 -342.59,-90.88 -215.27,-619.71 140.11,-524.74 123.53,33.01 236.83,175.89 187.97,338.93zm-12.49 -1159.24c-245.14,24.48 -424.95,181.71 -511.06,326.81 -112.91,190.24 -150.56,437.38 -138.14,690.66 24.06,490.56 321.11,870.13 844.83,812.96 517.96,-56.52 679.76,-567.34 649.12,-1017.56 -33.69,-495.06 -309.21,-866.34 -844.75,-812.87z"/>
          <path fill="#FEFFFE" d="M1232.8 2379.11c-34.26,-365.58 499.72,-404.5 539.09,-65.02 9.37,80.81 -18.14,156.73 -57.18,204.79 -157.47,193.9 -460.33,90.57 -481.92,-139.77zm-146.86 -1111.81c-252.81,25.26 -417.66,180.2 -508.49,329.9 -167.39,275.89 -182.17,716.82 -64.5,1037.36 35.01,95.37 101.48,208.1 162.53,259.41 80.6,67.75 84.75,103.26 248.29,163.89 329.91,122.3 687.1,10.01 876.63,-293.53 113.14,-181.2 147.89,-446.51 133.64,-690.97 -28.77,-493.56 -319.68,-858.87 -848.1,-806.06z"/>
          <path fill="#221D1F" d="M3527.4 2426.01c48.86,-163.04 -64.44,-305.91 -187.97,-338.93 -355.38,-94.96 -482.7,433.87 -140.11,524.74 160,42.44 290.99,-62.05 328.08,-185.82z"/>
          <path fill="#221D1F" d="M1232.8 2379.11c21.59,230.33 324.44,333.66 481.92,139.77 39.04,-48.06 66.55,-123.98 57.18,-204.79 -39.37,-339.48 -573.36,-300.55 -539.09,65.02z"/>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
