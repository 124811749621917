import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import {toArray, mapValues} from 'lodash';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// hooks
import useAccessControl from '../hooks/accessControl';
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------
// const Loadable = (Component) => (props) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { pathname } = useLocation();
//   const isDashboard = pathname.includes('/dashboard');
//   return (
//     <Suspense fallback={<LoadingScreen isDashboard={false} sx={{
//         ...(!isDashboard && {
//           top: 0,
//           left: 0,
//           width: 1,
//           zIndex: 9999,
//           position: 'fixed'
//         })
//       }} />}>
//       <Component {...props} />
//     </Suspense>
//   );
// };

const Loadable = (Component) => (props) =>  (
  <Suspense fallback={<LoadingScreen isDashboard={false}
    sx={{
      top: '80px',
      position: 'relative',
    }}
  />}>
    <Component {...props} />
  </Suspense>
);


export default function Router() {
  const { user } = useAuth();
  const arrVal = (user?.roles?.length) ? mapValues(user?.roles, 'key') : [];
  const userCRole = toArray(arrVal)

  const storeEdit = (userCRole?.length && (userCRole.includes('SUPER_ADMIN') || userCRole.includes('STORE_ADMIN')));

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          children: [
            {path: '', element: <GuestGuard><ResetPassword /></GuestGuard> },
            { path: ':token', element: <GuestGuard><ResetPassword /></GuestGuard> },
          ]
        },
        { path: '', element: <Navigate to="/404" replace /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <RoleBasedGuard isAccess><GeneralApp /></RoleBasedGuard> },
        { path: ':id/chat-demo', element: <ChatDemo /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard isAccess={useAccessControl('readAny','USERS')}><UserList /></RoleBasedGuard> },
            { path: 'new', element: <RoleBasedGuard isAccess={useAccessControl('createAny','USERS')}><UserCreate /></RoleBasedGuard> },
            { path: ':id/edit', element: <RoleBasedGuard isAccess={useAccessControl('editAny','USERS')}><UserCreate /></RoleBasedGuard> }
          ],
        },
        {
          path: 'organization',
          children: [
            { element: <Navigate to="/dashboard/organization/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard isAccess={useAccessControl('readAny','STORE')}><OrganizationList /></RoleBasedGuard> },
            { path: 'new', element: <RoleBasedGuard isAccess={useAccessControl('createAny','STORE')}><OrganizationCreate /></RoleBasedGuard> },
            { path: ':id/edit', element: <RoleBasedGuard isAccess={useAccessControl('editAny','STORE')}><OrganizationCreate /></RoleBasedGuard> },
            { path: 'manage', element: <RoleBasedGuard isAccess={useAccessControl('editAny','PRODUCT_MANAGER')}><StoreManage /></RoleBasedGuard> },
          ],
        },
        { path: 'inventory', element: <RoleBasedGuard isAccess={useAccessControl('readAny','PRODUCTS')}><InventoryList /></RoleBasedGuard>},
        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/product/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard  isAccess={useAccessControl('readAny','PRODUCTS')}><ProductList /></RoleBasedGuard> },
            { path: 'new', element: <RoleBasedGuard  isAccess={useAccessControl('createAny','PRODUCTS')}><ProductCreate /></RoleBasedGuard> },
            { path: ':id/edit', element: <RoleBasedGuard  isAccess={useAccessControl('editAny','PRODUCTS')}><ProductCreate /></RoleBasedGuard> },

            { path: 'review', element: <RoleBasedGuard isAccess={useAccessControl('readAny','PRODUCT_REVIEW')}><ReviewList /></RoleBasedGuard>},
            { path: 'category', element: <RoleBasedGuard isAccess={useAccessControl('readAny','CATEGORIES')}><CategoryList /></RoleBasedGuard>},
            { path: 'brand', element: <RoleBasedGuard isAccess={useAccessControl('readAny','BRANDS')}><BrandList /></RoleBasedGuard>},
            { path: 'strain', element: <RoleBasedGuard isAccess={useAccessControl('readAny','STRAINS')}><StrainList /></RoleBasedGuard>},
          ]
        },
        { path: 'manage-mesh', element: <RoleBasedGuard isAccess={useAccessControl('editAny','PRODUCT_MANAGER')}><StoreMeshManage /></RoleBasedGuard> },
        { path: 'inquiry-list', element: <RoleBasedGuard isAccess={useAccessControl('readAny','CONTACT_FORM_ENTRY')}><InquiryList /></RoleBasedGuard> },
        { path: 'store', element: <RoleBasedGuard isAccess={useAccessControl('editAny','PRODUCT_MANAGER')}><StoreThreeD /></RoleBasedGuard> },

        { path: 'catalog',
          children: [
            { element: <Navigate to="/dashboard/catalog/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard isAccess={useAccessControl('readAny','CATALOG')}><Catalog /></RoleBasedGuard> },
            { path: 'match', element: <RoleBasedGuard isAccess={useAccessControl('readAny','CATALOG_MATCHING')}><CatalogMatch /></RoleBasedGuard> },
          ]
        },
        { path: 'media', element: <RoleBasedGuard isAccess={useAccessControl('readAny','CATALOG_IMAGES')}><Media /></RoleBasedGuard> },
        { path: 'orders', element: <RoleBasedGuard isAccess={useAccessControl('readAny','ORDERS')}><OrderList /></RoleBasedGuard> },

        { path: 'reminder',
          children: [
            { element: <Navigate to="/dashboard/reminder/cart-list" replace />, index: true },
            { path: 'cart-list', element: <RoleBasedGuard isAccess={useAccessControl('readAny','ABANDONED_CART')}><CartList /></RoleBasedGuard>},
            { path: 'favorite', element: <RoleBasedGuard isAccess={useAccessControl('readAny','FAVORITE_LIST_EMAIL')}><FavoriteList /></RoleBasedGuard>},
            { path: 'wishlist', element: <RoleBasedGuard isAccess={useAccessControl('readAny','WISH_LIST_EMAIL')}><WishList /></RoleBasedGuard>},
            { path: 'recently-viewed', element: <RoleBasedGuard isAccess={useAccessControl('readAny','RECENT_VIEW_EMAIL')}><RecentlyViewedList /></RoleBasedGuard>},
            { path: 'notification', element: <RoleBasedGuard isAccess={useAccessControl('readAny','FIREBASE_NOTIFICATION_CRUD')}><NotificationList /></RoleBasedGuard>},
          ]
        },
        { path: 'newsletter',
          children: [
            { path:'', element: <Navigate to="/dashboard/newsletter/register-users" replace /> },
            { path: 'register-users', element: <RoleBasedGuard isAccess={useAccessControl('readAny','SMS')}><NewsletterUserList /></RoleBasedGuard> },
            { path: 'contacts', element: <RoleBasedGuard isAccess={useAccessControl('readAny','SMS')}><ContactList /></RoleBasedGuard> },
            { path: 'sms-analytics', element: <RoleBasedGuard isAccess={useAccessControl('readAny','SMS')}><SmsAnalytics /></RoleBasedGuard> },
            { path: 'sms-analytics/:id', element: <RoleBasedGuard isAccess={useAccessControl('readAny','SMS')}><SmsAnalyticsView /></RoleBasedGuard> },
            { path: 'email-analytics', element: <RoleBasedGuard isAccess={useAccessControl('readAny','SMS')}><EmailAnalytics /></RoleBasedGuard> },
            { path: 'email-analytics/:id', element: <RoleBasedGuard isAccess={useAccessControl('readAny','SMS')}><EmailAnalyticsView /></RoleBasedGuard> },
          ]
        },
        {
          path: 'chat',
          children: [
            { element: <RoleBasedGuard isAccess={useAccessControl('readAny','CHAT')}><Chat /></RoleBasedGuard>, index: true },
            { path: 'new', element: <RoleBasedGuard isAccess={useAccessControl('readAny','CHAT')}><Chat /></RoleBasedGuard> },
            { path: ':conversationKey', element: <RoleBasedGuard isAccess={useAccessControl('readAny','CHAT')}><Chat /></RoleBasedGuard> },
          ],
        },
        { path: 'location', element: <RoleBasedGuard isAccess={useAccessControl('readAny','RESTRICTED_AREA')}><LocationList /></RoleBasedGuard> },
        { path: 'app-rating', element: <RoleBasedGuard isAccess={useAccessControl('readAny','APP_RATING')}><AppRatingList /></RoleBasedGuard> },
        { path: 'support', element: <RoleBasedGuard isAccess={useAccessControl('readAny','SUPPORT_TICKET')}><SupportList /></RoleBasedGuard> },
        { path: 'settings',
          children: [
            { path:'', element: <Navigate to="/dashboard/settings/account" replace /> },
            { path: 'account', element: <RoleBasedGuard isAccess><UserAccount /></RoleBasedGuard> },
            { path: 'permission', element: <RoleBasedGuard isAccess={useAccessControl('createAny','permission_AA')}><RolePermission /></RoleBasedGuard> },
            { path: 'slider-list', element: <RoleBasedGuard isAccess={useAccessControl('createAny','CMS')}><SliderHero /></RoleBasedGuard> },
            { path: 'faqs', element: <RoleBasedGuard isAccess={useAccessControl('readAny','FAQ')}><FaqList /></RoleBasedGuard> },
            { path: 'other-pages', element: <RoleBasedGuard isAccess={useAccessControl('readAny','PAGE')}><OtherPages /></RoleBasedGuard> },
            { path: 'store/:id/edit', element: <RoleBasedGuard isAccess={storeEdit}><UserStoreSettings /></RoleBasedGuard> },
          ]
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN}replace={false} />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// DASHBOARD
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// Organization (Store)
const OrganizationList = Loadable(lazy(() => import('../pages/dashboard/OrganizationList')));
const OrganizationCreate = Loadable(lazy(() => import('../pages/dashboard/OrganizationCreate')));
const StoreManage = Loadable(lazy(() => import('../pages/dashboard/StoreManage')));
const StoreMeshManage = Loadable(lazy(() => import('../pages/dashboard/StoreMeshManage')));

const Catalog = Loadable(lazy(() => import('../pages/dashboard/Catalog')));
const CatalogMatch = Loadable(lazy(() => import('../pages/dashboard/CatalogMatch')));

// SETTINGS
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const RolePermission = Loadable(lazy(() => import('../pages/dashboard/RolePermission')));
const SliderHero = Loadable(lazy(() => import('../pages/dashboard/SliderHero')));
const FaqList = Loadable(lazy(() => import('../pages/dashboard/FaqList')));
const OtherPages = Loadable(lazy(() => import('../pages/dashboard/OtherPages')));
const UserStoreSettings = Loadable(lazy(() => import('../pages/dashboard/UserStoreSettings')));

// E-Commerce
const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));
const ReviewList = Loadable(lazy(() => import('../pages/dashboard/ReviewList')));
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/CategoryList')));
const BrandList = Loadable(lazy(() => import('../pages/dashboard/BrandList')));
const StrainList = Loadable(lazy(() => import('../pages/dashboard/StrainList')));
const InventoryList = Loadable(lazy(() => import('../pages/dashboard/InventoryList')));

// Media
const Media = Loadable(lazy(() => import('../pages/dashboard/Media')));
// OrderList
const OrderList = Loadable(lazy(() => import('../pages/dashboard/OrderList')));
const CartList = Loadable(lazy(() => import('../pages/dashboard/CartList')));
const FavoriteList = Loadable(lazy(() => import('../pages/dashboard/FavoriteList')));
const WishList = Loadable(lazy(() => import('../pages/dashboard/WishList')));
const RecentlyViewedList = Loadable(lazy(() => import('../pages/dashboard/RecentlyViewedList')));
const NotificationList = Loadable(lazy(() => import('../pages/dashboard/NotificationList')));

// Newsletter
const ContactList = Loadable(lazy(() => import('../pages/dashboard/ContactList')));
const NewsletterUserList = Loadable(lazy(() => import('../pages/dashboard/NewsletterUserList')));
const SmsAnalytics = Loadable(lazy(() => import('../pages/dashboard/SmsAnalytics')));
const SmsAnalyticsView = Loadable(lazy(() => import('../pages/dashboard/SmsAnalyticsView')));
const EmailAnalytics = Loadable(lazy(() => import('../pages/dashboard/EmailAnalytics')));
const EmailAnalyticsView = Loadable(lazy(() => import('../pages/dashboard/EmailAnalyticsView')));
const InquiryList = Loadable(lazy(() => import('../pages/dashboard/InquiryList')));

const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const LocationList = Loadable(lazy(() => import('../pages/dashboard/LocationList')));
const AppRatingList = Loadable(lazy(() => import('../pages/dashboard/AppRatingList')));
const SupportList = Loadable(lazy(() => import('../pages/dashboard/SupportList')));

const ChatDemo = Loadable(lazy(() => import('../pages/ChatDemo')));
const StoreThreeD = Loadable(lazy(() => import('../pages/dashboard/StoreThreeD')));

// MAIN
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));